<!--footer section -->
<footer [class]="class">
	<!--<div class="light-layout" *ngIf="newsletter">
		<div class="container">
			<section class="small-section border-section border-top-0">
				<div class="row">
					<div class="col-lg-6">
						<div class="subscribe">
							<div>
								<h4>KNOW IT ALL FIRST!</h4>
								<p>Never Miss Anything From Multikart By Signing Up To Our Newsletter. </p>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<form class="form-inline subscribe-form" action="https://pixelstrap.us19.list-manage.com/subscribe/post?u=5a128856334b598b395f1fc9b&amp;id=082f74cbda" target="_blank" method="post">
							<div class="form-group mx-sm-3">
								<input type="email" #name name="EMAIL" id="mce-EMAIL" class="form-control" placeholder="Enter your email">
							</div>
							<button type="submit" class="btn btn-solid">subscribe</button>
						</form>
					</div>
				</div>
			</section>
		</div>
	</div>-->
	<section class="section-b-space light-layout">
		<div class="container">
			<div class="row footer-theme partition-f">
				<div class="col-lg-4 col-md-6">
					<div class="footer-title footer-mobile-title">
						<h4></h4>
					</div>
					<div class="footer-contant">
						<div class="footer-logo">
							<img [src]="themeLogo" alt="Glad Handel"  style="max-width: 200px;">
						</div>
						<p></p>
						<p>Vi hör av oss så fort det går och oftast mellan 1-12 timmar. </p>
						<div class="footer-social">
                            <ul>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                </li>
                                <!-- <li>
                                    <a href="javascript:void(0)"><i class="fa fa-google-plus" aria-hidden="true"></i></a>                 
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-rss" aria-hidden="true"></i></a>
                                </li> -->
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col offset-xl-1">
					<div class="sub-title">
						<div class="footer-title">
                            <h4></h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
								<li><a routerLink="home/gladasteg" fragment ="start" >Home</a></li>
                                <li><a routerLink="home/gladasteg" fragment ="systemutveckling">Systemutveckling</a></li>
                                <li><a routerLink="home/gladasteg" fragment ="e-handel">E-handelslösningar</a></li>                                
                            </ul>
                        </div>
					</div>
				</div>
				<!-- <div class="col">
					<div class="sub-title">
						<div class="footer-title">
                            <h4>why we choose</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a href="javascript:void(0)">shipping & return</a></li>
                                <li><a href="javascript:void(0)">secure shopping</a></li>
                                <li><a href="javascript:void(0)">gallary</a></li>
                                <li><a href="javascript:void(0)">affiliates</a></li>
                                <li><a href="javascript:void(0)">contacts</a></li>
                            </ul>
                        </div>
					</div>
				</div> -->
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
						    <h4>Kontakta oss</h4>
						</div>
						<div class="footer-contant">
						    <ul class="contact-list">
						        <!-- <li><i class="fa fa-map-marker"></i>Glad Handel</li> -->
						        <li><i class="fa fa-phone"></i>Telefon: 070 7787004</li>
						        <li><i class="fa fa-envelope-o"></i>E-post: info@gladhandel.se</li>
						       
						    </ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<div class="sub-footer">
		<div class="container">
			<div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="footer-end">
                    <p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} Glad Handel</p>
                  </div>
                </div>
                <!-- <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="payment-card-bottom">
                    <ul>
                      <li>
                        <a><img src="assets/images/icon/visa.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/mastercard.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/paypal.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/american-express.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/discover.png" alt=""></a>
                      </li>
                    </ul>
                  </div>
                </div> -->
            </div>
		</div>
	</div>
</footer>
<!--footer section end -->