<div class="main-navbar">
  <div id="mainnav">
    <div class="toggle-nav" (click)="mainMenuToggle()">
      <i class="fa fa-bars sidebar-bar"></i>
    </div>
    <ul class="nav-menu" [class.opennav]="navServices?.mainMenuToggle">

      <li class="back-btn">
        <div class="mobile-back text-end" (click)="mainMenuToggle()">
          <span>Tillbaka</span>
          <i class="fa fa-angle-right ps-2" aria-hidden="true"></i>
        </div>
      </li>

      <li *ngFor="let menuItem of menuItems" [class.mega-menu]="menuItem.megaMenu">
        <!-- Sub -->
       
        <!-- Link -->
        <a  routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link"
          *ngIf="menuItem.type === 'link'" routerLink="menuItem.path" fragment ={{menuItem.fragment}}>
          {{ menuItem.title }}
         
        </a>
        
        

       
      </li>
    </ul>
  </div>
</div>
