import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Menu
export interface Menu {
	path?: string;
	fragment?: string;
	title?: string;
	type?: string;
	megaMenu?: boolean;
	image?: string;
	active?: boolean;
	badge?: boolean;
	badgeText?: string;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	constructor() { }

	public screenWidth: any;
	public leftMenuToggle: boolean = false;
	public mainMenuToggle: boolean = false;

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		{
			path: '/home/gladasteg', title: 'home', type: 'link', fragment: 'start'
		},
		{
			path: '/home/gladasteg', title: 'systemutveckling', type: 'link', fragment: 'systemutveckling'
		},
		{
			path: '/home/gladasteg', title: 'e-handelslösningar', type: 'link', fragment: 'e-handel'
		},
		{
			path: '/home/gladasteg', title: 'kontakta oss', type: 'link', fragment: 'contact'
		}
		
	];

	LEFTMENUITEMS: Menu[] = [
		{
			path: '/home/gladasteg', title: 'home', type: 'link'
		},
		{
			path: '/home/gladasteg', title: 'systemutveckling', type: 'link'
		},
		{
			path: '/home/gladasteg', title: 'e-handelslösningar', type: 'link'
		},
		{
			path: '/home/gladasteg', title: 'kontakta oss', type: 'link'
		}
		
	];

	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
	leftMenuItems = new BehaviorSubject<Menu[]>(this.LEFTMENUITEMS);

}
